import React, { Component } from 'react'
import Modules from '../../Component/Pages/Modules'

class Module extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
}
    componentDidMount() {
        const { modulerequest } = this.props;
        modulerequest();  // Dispatch the action to fetch data
        // console.log('module=====>',modulerequest)
      }
  render() {
    const {fecheddata, modules} = this.props
    return (
      <div>
        <Modules 
          fecheddata={fecheddata}
          modules={modules}
        />
      </div>
    )
  }
}

export default Module
