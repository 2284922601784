import React, { useState, useEffect } from 'react';

function Resources({ resourcedata, isLoading }) {
    const [resourceData, setResourceData] = useState([]);

    useEffect(() => {
        if (resourcedata !== undefined) {
            setResourceData(resourcedata);
        }
    }, [resourcedata]);

    if (isLoading) {
        return <p>Loading resources...</p>;
    }

    return (
        <>
            <h3 style={{ fontWeight: 'bold', marginLeft: '2%' }}>Resource</h3>
            <div style={styles.gridContainer}>
                {resourceData.map((resource) => (
                    <div key={resource.id} style={styles.card}>
                        <img
                            src={resource.resource_thumbnail}
                            alt={resource.resource_title}
                            style={styles.image}
                        />
                        <h3>{resource.resource_title}</h3>
                        <p>Discover health insurance options tailored for you. Explore now.</p>
                    </div>
                ))}
            </div>
        </>
    );
}

const styles = {
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
        gap: '20px',
        padding: '20px',
    },
    card: {
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '16px',
        textAlign: 'center',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    image: {
        width: '100%',
        height: '150px',
        objectFit: 'cover',
        borderRadius: '8px 8px 0 0',
    },
};

export default Resources;
