import React,{useState, useEffect} from 'react'
import '../Style/Polls.css'

function Poll({
    Polls
}) {

    const [PollsData, setPollsData] = useState([])


    useEffect(()=>{
        if(Polls !== undefined){
            setPollsData(Polls)
        }
    },[Polls])


  return (
    <>
            <h3 style={{ fontWeight:'bold', marginLeft:'2%' }}>Polls</h3>
            <div className="polls-container">
                {PollsData.map((polls, index) => (
                    <div className="polls-card" key={index}>
                        <img src={polls.thumbnail} alt={polls.module_test} className="polls-thumbnail" />
                        <div className="polls-info">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p><strong>{polls.id} Question</strong></p>
                                <p>⏱ 2 mins</p>
                            </div>
                            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{polls.module_test}</span>
                            <div style={{ display:'flex', justifyContent:'space-between', marginTop:'10%' }}>
                                <p style={{ alignContent:'center' }}>21 Attend</p>
                                <button className="polls-btn">Play Quiz</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
  )
}

export default Poll
