import React, { useEffect, useState } from 'react';
import { FaClock } from 'react-icons/fa';
import '../Style/Modules.css'

function Modules({ modules }) {
  const [modulesData, setModulesData] = useState([]);

  useEffect(() => {
    if (modules !== undefined) {
      setModulesData(modules);
    }
  }, [modules]);

  return (
    <div className="modules-container">
      <h3>Modules</h3>
      <div className="modules-grid">
        {modulesData.map((module, index) => (
          <div key={index} className="module-card">
            <img
              src={module.thumbnail || 'https://via.placeholder.com/150'}
              alt={`${module.module_title} Thumbnail`}
              className="module-thumbnail"
            />
            <h4>{module.module_title}</h4>
            <p>{module.module_description}</p>
            <div className="progress-bar">
              <div className="progress" style={{ width: '40%' }}></div>
            </div>
            <div className="module-footer">
              <div>
                <FaClock className="icon" />
                {/* {module.module_type} */}
              </div>
              <div>
                <FaClock className="icon" />
                {module.module_type}
              </div>
            </div>
            <div style={{ marginBottom:'15%' }}>
              <button className="enroll-button">Enroll Now</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Modules;
