import { put, takeEvery, call } from 'redux-saga/effects';
import axios from 'axios';
import { POLLS_DATA_FETCH_REQUEST } from './actionType';
import { pollsfetchsuccess, pollsfetchfailure } from './action';
import { Api } from '../../common/Api';

function* PollSaga(action) {
    console.log('polls-action', action.payload)
    try {
        const response = yield call(axios.get, `${Api.Apiurl}/external/home/poll`, {
            params: action.payload
        });
        yield put(pollsfetchsuccess(response.data));
        // console.log('responece----->', response.data)
    } catch (error) {
        // console.log('error--------->', error)
        yield put(pollsfetchfailure(error.message));
    }
}

export function* pollrootSaga() {
    yield takeEvery(POLLS_DATA_FETCH_REQUEST, PollSaga);
}
