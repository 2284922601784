import React, { useEffect, useState } from 'react';
import { FaClock } from 'react-icons/fa';
import '../Style/Modules.css'

function Modules({ 
  course,
  isLoading,
 }) {
  const [courseData, setcourseData] = useState([]);

  useEffect(() => {
    if (course !== undefined) {
      setcourseData(course);
    }
  }, [course]);

  if(isLoading){
    return (
      <div>Loading....</div>
    )
  }

  return (
    <div className="modules-container">
      <h3>Course</h3>
      <div className="modules-grid">
        {courseData.map((course, index) => (
          <div key={index} className="module-card">
            <img
              src={course.thumbnail || 'https://via.placeholder.com/150'}
              alt={`${module.module_title} Thumbnail`}
              className="module-thumbnail"
            />
            <h4>{course.module_title}</h4>
            <p>{course.module_description}</p>
            <div className="progress-bar">
              <div className="progress" style={{ width: '40%' }}></div>
            </div>
            <div className="module-footer">
              <div>
                <FaClock className="icon" />
                {/* {module.module_type} */}
              </div>
              <div>
                <FaClock className="icon" />
                {course.module_type}
              </div>
            </div>
            <div style={{ marginBottom:'15%' }}>
              <button className="enroll-button">Enroll Now</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Modules;
