// src/Component/ReusableModal.js
import React from 'react';
import '../Component/Style/Modal.css'

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="reusable-modal">
      <div className="reusable-modal-content">
        <button className="close-button" onClick={onClose}>X</button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
