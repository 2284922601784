import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../Style/Home.css'

const Header = () => {
  // const [isMobileView, setIsMobileView] = useState(false);
  // const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const user = 'Monish'; // Replace with your actual user data

  const location = useLocation();

  const isLoggedIn = () => {
    return true; // Example implementation, replace with actual logic
  };

  // const toggleSidebar = () => {
  //   setIsMobileView(!isMobileView);
  // };

  // const toggleMenu = () => {
  //   setIsMenuOpen(!isMenuOpen);
  // };

  const search = (term) => {
    console.log('Searching for:', term);
  };

  const logout = () => {
    console.log('Logging out');
  };

  const isActive = (path) => location.pathname === path ? 'active' : '';

  return (
    <>
      <header>
          <>
            <div className="logo">
              <h2>Logo</h2>
            </div>
            <nav>
              <ul>
                <li className={isActive('/')}>
                  <a  href="/">
                    Home
                  </a>
                </li>
                <li className={isActive('/program')}>
                  <a href="/program">
                    Program
                  </a>
                </li>
                <li  className={isActive('/products')}>
                  <a href='/products'>Products</a>
                </li>
                <li  className={isActive('/about')}>
                  <a href='/about'>
                    about us
                  </a>
                </li>
              </ul>
            </nav>
            <div className="search-bar">
              <div className="search-container">
                <input
                  className="header-input"
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search anything...."
                />
                <button className="search-btn" onClick={() => search(searchTerm)}>
                  Search
                </button>
              </div>
            </div>
            <div className="right">
              {isLoggedIn() ? (
                <>
                  <div className="icon-container">
                    <i className="fa-solid fa-bell"></i>
                  </div>
                  <div className="user-container">
                    {user}&nbsp;
                    <i className="fa-solid fa-user"></i>
                  </div>
                  <div className="logout-container">
                    <i className="fa-solid fa-power-off" onClick={logout}></i>
                  </div>
                </>
              ) : (
                <button className="signup-btn">
                  <a href="/login">Login</a>
                </button>
              )}
            </div>
          </>
      </header>
      <div className="sub-header">
        <a className={`nav-link ${isActive('/')}`} href="/">News Feed</a>
        <a className={`nav-link ${isActive('/modules')}`} href="/modules">Modules</a>
        <a className={`nav-link ${isActive('/course')}`} href="/course">Courses</a>
        <a className={`nav-link ${isActive('/case')}`} href="/case">Cases</a>
        <a className={`nav-link ${isActive('/webinar')}`} href="/webinar">Webinars</a>
        <a className={`nav-link ${isActive('/quiz')}`} href="/quiz">Quiz</a>
        <a className={`nav-link ${isActive('/poll')}`} href="/poll">Polls</a>
        <a className={`nav-link ${isActive('/resource')}`} href="/resource">Resources</a>
        <a className={`nav-link ${isActive('/games')}`} href="/games">Games</a>
      </div>
    </>
  );
};

export default Header;
