import { put, takeEvery, call } from 'redux-saga/effects';
import axios from 'axios';
import { WEBINAR_DATA_REQUEST } from './actionType';
import { WebinarRequestsuccess, WebinarRequestfailure } from './action';
import { Api } from '../../common/Api';

function* WebinarSaga(action) {
    try {
        const response = yield call(axios.get, `${Api.Apiurl}external/home/webinar`, {
            params: action.payload
        });
        yield put(WebinarRequestsuccess(response.data));
    } catch (error) {
        yield put(WebinarRequestfailure(error.message));
    }
}

export function* WebinarrootSaga() {
    yield takeEvery(WEBINAR_DATA_REQUEST, WebinarSaga);
}
