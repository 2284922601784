import React, { useState } from 'react';
import '../Style/Newsfeed.css';

function NewsFeed({ newsfeed, onNewsClick }) {

  const [likedNews, setLikedNews] = useState(new Set());

  const toggleLike = (event, newsId) => {
    event.stopPropagation();
    setLikedNews(prevLikedNews => {
      const newLikedNews = new Set(prevLikedNews);
      if (newLikedNews.has(newsId)) {
        newLikedNews.delete(newsId);
      } else {
        newLikedNews.add(newsId);
      }
      return newLikedNews;
    });
  };

  const onCardClicked = (news) => {
    onNewsClick(news);
    // Handle card click logic
  };

  return (
    <div>
    <div style={{fontWeight: 'bold', fontSize: '2rem'}}>
    <h3 style={{ fontWeight: 'bold', marginLeft: '2%' }}>News Feed</h3>
    </div>
      <div className="container">
        <div className="row">
          {newsfeed.map(news => (
            <div className="col-md-4 mb-4" key={news.id}>
              <div className="news-item" onClick={() => onCardClicked(news)}>
                <div className="card h-100">
                  <img
                    src={news.thumbnail}
                    className="card-img-top"
                    alt={news.title}
                    style={{ height: '20rem' }}
                  />
                  <div className="card-body">
                    <div className="news-date">
                      <div className="meta">
                        <span>{new Date(news.date).toLocaleDateString('en-US', { day: '2-digit', month: 'long', year: 'numeric' })}</span>
                      </div>
                    </div>
                    <h5 className="card-title">{news.title}</h5>
                    <p className="card-text">
                      {news.descrption}
                    </p>
                    <div className="date">
                      <div>
                        <button
                          type="button"
                          className={`btn btn-outline-primary like-button ${likedNews.has(news.id) ? 'liked' : ''}`}
                          onClick={(e) => toggleLike(e, news.id)}
                        >
                          <span><i className="fa-regular fa-thumbs-up"></i>&nbsp;</span>
                          <span className="like-text">Like</span>
                        </button>
                      </div>
                      <div>
                        <button type="button" className="btn btn-outline-secondary">
                          <span><i className="fa-solid fa-share"></i></span>&nbsp;Share
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default NewsFeed;
