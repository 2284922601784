import React, { useState, useEffect } from 'react'
import '../Style/quize.css'

function Quiz({
    quize,
    isLoading
}) {
    const [quizeData, setquizeData] = useState([])

    useEffect(() => {
        if (quize !== undefined) {
            setquizeData(quize)
        }
    }, [quize])

    return (
        <>
            <h3 style={{ fontWeight:'bold', marginLeft:'2%' }}>Quiz</h3>
            <div className="quiz-container">
                {quizeData.map((quiz) => (
                    <div className="quiz-card">
                        <img src={quiz.thumbnail} alt={quiz.module_test} className="quiz-thumbnail" />
                        <div className="quiz-info">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p><strong>{quiz.id} Question</strong></p>
                                <p>⏱ 2 mins</p>
                            </div>
                            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{quiz.module_test}</span>
                            <div style={{ display:'flex', justifyContent:'space-between', marginTop:'10%' }}>
                                <p style={{ alignContent:'center' }}>21 Attend</p>
                                <button className="play-quiz-btn">Play Quiz</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default Quiz
