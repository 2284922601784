import { put, takeEvery, call } from 'redux-saga/effects';
import axios from 'axios';
import { RESOURCE_FETCH_REQUEST } from './actionType';
import { resourcefetchsuccess, resourcefetchfailure } from './action';
import { Api } from '../../common/Api';

function* ResourceSaga(action) {
    try {
        const response = yield call(axios.get, `${Api.Apiurl}/external/home/resource`, {
            params: action.payload
        });
        yield put(resourcefetchsuccess(response.data));
    } catch (error) {
        yield put(resourcefetchfailure(error.message));
    }
}

export function* ResourcerootSaga() {
    yield takeEvery(RESOURCE_FETCH_REQUEST, ResourceSaga);
}
