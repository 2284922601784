import { Routes, Route } from 'react-router-dom'
import Header from '../Component/Pages/Home';
import NewsFeedRedux from '../Container/NewsFeed/NewsFeed-redux';
import ModuleRedux from '../Container/Modules/Module-redux';
import CoursesRedux from '../Container/Courses/Courses-redux';
import CaseRedux from '../Container/Cases/Case-redux';
import WebinarRedux from '../Container/Webinars/Webinar-redux';
import QuizeRedux from '../Container/quize/Quize-redux';
import PollsRedux from '../Container/polls/Polls-redux';
import ResourceRedux from '../Container/Resource/Resource-redux';

function Navigation() {
    return (
        <>
            <Header />
            <Routes>
                <Route path='/' element={<NewsFeedRedux />} />
                <Route path='/modules' element={<ModuleRedux />} />
                <Route path='/course' element={<CoursesRedux />} />
                <Route path='/case' element={<CaseRedux /> }/>
                <Route path='/webinar' element={<WebinarRedux />} />
                <Route path='/quiz' element={<QuizeRedux />} />
                <Route path='/poll' element={<PollsRedux />} />
                <Route  path='/resource' element={<ResourceRedux />} />
            </Routes>
        </>
    );
}

export default Navigation;
