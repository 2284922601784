import { put, takeEvery, call } from 'redux-saga/effects';
import axios from 'axios';
import { NEWS_FEED_REQUEST } from './actionTypes';
import { newsfeedfetchsuccess, newsfeedfetchfailure } from './action';
import { Api } from '../../common/Api';

function* newsfeedSaga(action) {
    try {
        const response = yield call(axios.get, `${Api.Apiurl}/external/home/newsfeed`, {
            params: action.payload
        });
        yield put(newsfeedfetchsuccess(response.data));
    } catch (error) {
        yield put(newsfeedfetchfailure(error.message));
    }
}

export function* newsrootSaga() {
    yield takeEvery(NEWS_FEED_REQUEST, newsfeedSaga);
}
